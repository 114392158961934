import gsap from 'gsap'

const CURSOR_SELECTOR = '.js-cursor'
const TOP_MARKER_SELECTOR = '[data-glow-cursor-top-marker]'
const BOTTOM_MARKER_SELECTOR = '[data-glow-cursor-bottom-marker]'
const MOBILE_BREAKPOINT = 768
const EXPAND_HEIGHTS = { TOP: 779, BOTTOM: 556 }
const OFFSETS_TO_EXPAND = { TOP: 519, BOTTOM: 222 }
const DEFAULT_ANIMATION_VARS = { duration: 0.4, ease: 'expo' }
const BLUR_AMOUNTS = { SHRINKED: 75, EXPANDED: 100 }

const GLOW_SIZE = 400
const GLOW_STATES = {
  SHOULD_SHRINK: 0,
  SHRINKING: 1, SHRINKED: 2,
  SHOULD_EXPAND_TOP: 3, EXPANDING_TOP: 4, EXPANDED_TOP: 5,
  SHOULD_EXPAND_BOTTOM: 6, EXPANDING_BOTTOM: 7, EXPANDED_BOTTOM: 8
}
const GLOW_BACKGROUNDS = {
  // SHRINK_LIGHT: 'radial-gradient(59.55% 74% at 50% 38.6%, #8DCEFD 0%, rgba(189, 212, 255, 0.93) 74.86%, rgba(184, 117, 255, 0.69) 87.7%, rgba(128, 125, 255, 0) 100%)',
  SHRINK_LIGHT: 'radial-gradient(58.15% 52.14% at 50% 50%, rgba(4, 57, 245, 0.776) 0.52%, rgba(4, 11, 245, 0.592) 64.55%, rgba(110, 4, 245, 0.248) 80.21%)',
  SHRINK_DARK: 'radial-gradient(58.15% 52.14% at 50% 50%, rgba(4, 57, 245, 0.776) 0.52%, rgba(4, 11, 245, 0.592) 64.55%, rgba(110, 4, 245, 0.248) 80.21%)',
  // EXPAND_LIGHT_TOP: 'radial-gradient(133.99% 97.44% at 49.98% 0%, #8DCEFD 0%, rgba(189, 212, 255, 0.93) 88.54%, rgba(136, 117, 255, 0.81) 92.19%, rgba(128, 125, 255, 0) 100%)',
  EXPAND_LIGHT_TOP: 'radial-gradient(95.66% 85.78% at 50.03% 16.36%, rgba(4, 57, 245, 0.7) 0.52%, rgba(48, 54, 252, 0.518) 76.04%, rgba(4, 100, 245, 0.217) 100%)',
  EXPAND_DARK_TOP: 'radial-gradient(95.66% 85.78% at 50.03% 16.36%, rgba(4, 57, 245, 0.7) 0.52%, rgba(48, 54, 252, 0.518) 76.04%, rgba(4, 100, 245, 0.217) 100%)',
  // EXPAND_LIGHT_BOTTOM: 'radial-gradient(99.76% 132.42% at 50.02% 100%, #8DCEFD 0, rgba(189, 212, 255, 0.77) 70.43%, rgba(184, 117, 255, 0.66) 85.74%, rgba(128, 125, 255, 0) 100%)',
  EXPAND_LIGHT_BOTTOM: 'radial-gradient(95.66% 85.78% at 50.03% 16.36%, rgba(4, 57, 245, 0.7) 0.52%, rgba(48, 54, 252, 0.518) 76.04%, rgba(4, 100, 245, 0.217) 100%)',
  EXPAND_DARK_BOTTOM: 'radial-gradient(95.66% 85.78% at 50.03% 16.36%, rgba(4, 57, 245, 0.7) 0.52%, rgba(48, 54, 252, 0.518) 76.04%, rgba(4, 100, 245, 0.217) 100%)'
}

class GlowCursor extends HTMLElement {
  constructor () {
    super()

    this.cursor = this.querySelector(CURSOR_SELECTOR)
    this.glowState = GLOW_STATES.SHOULD_SHRINK
    this.mousePos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    this.quickTo = this.shrinkTimeline = this.expandTimeline = null

    this.topMakerEl = document.querySelector(TOP_MARKER_SELECTOR)
    this.bottomMakerEl = document.querySelector(BOTTOM_MARKER_SELECTOR)

    this.initGSAP()
    this.attachEvents()
  }

  initGSAP () {
    this.initQuickTo()

    gsap.ticker.add(this.update.bind(this))
    gsap.ticker.fps(120)
  }

  initQuickTo () {
    this.quickTo = {
      toT: gsap.quickTo(this.cursor, 'top', DEFAULT_ANIMATION_VARS),
      toR: gsap.quickTo(this.cursor, 'right', DEFAULT_ANIMATION_VARS),
      toB: gsap.quickTo(this.cursor, 'bottom', DEFAULT_ANIMATION_VARS),
      toL: gsap.quickTo(this.cursor, 'left', DEFAULT_ANIMATION_VARS)
    }
  }

  update () {
    if (this.isOnMobile()) this.mousePos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }

    this.checkStates()

    if (this.inShrinkStates()) {
      const cursorTop = this.mousePos.y - GLOW_SIZE / 2
      const cursorLeft = this.mousePos.x - GLOW_SIZE / 2

      this.resetAllQuickToDuration()
      this.quickTo.toT(cursorTop)
      this.quickTo.toR(cursorLeft + GLOW_SIZE)
      this.quickTo.toB(cursorTop + GLOW_SIZE)
      this.quickTo.toL(cursorLeft)
    } else {
      this.pauseAllQuickTo()
    }

    // Handle glow states
    switch (this.glowState) {
      case GLOW_STATES.SHOULD_SHRINK:
        this.shrink()
        break
      case GLOW_STATES.SHOULD_EXPAND_TOP:
        this.expand()
        break
      case GLOW_STATES.SHOULD_EXPAND_BOTTOM:
        this.expand(false)
        break
    }
  }

  attachEvents () {
    window.addEventListener('mousemove', this.saveMousePos.bind(this))

    document.addEventListener('theme-mode-changed', () => {
      gsap.to(this.cursor, {
        backgroundImage: this.getGlowBackground(),
        duration: 0.2,
        ease: 'power2'
      })
    })
  }

  saveMousePos (e) {
    this.mousePos = this.isOnMobile() ?
      { x: window.innerWidth / 2, y: window.innerHeight / 2 } :
      { x: e.clientX, y: e.clientY }
  }

  checkStates () {
    const currentScrollPos = Math.ceil(window.scrollY + window.innerHeight)
    const docHeight = document.body.offsetHeight
    const windowHeight = window.innerHeight
    const windowTouchedTop = window.scrollY <= 0
    const windowTouchedBottom = currentScrollPos >= docHeight
    const cursorBoundingReact = this.cursor.getBoundingClientRect()
    const cursorCenterY = cursorBoundingReact.y + GLOW_SIZE / 2
    const offsetToExpand = this.offsetToExpand()
    let touchedTop = windowTouchedTop
    let touchedBottom = windowTouchedBottom

    if (!this.isOnMobile()) {
      const inTopExpandArea = this.mousePos.y <= offsetToExpand.top && cursorCenterY <= offsetToExpand.top
      const inBottomExpandArea = this.mousePos.y >= windowHeight - offsetToExpand.bottom && cursorCenterY >= windowHeight - offsetToExpand.bottom

      touchedTop = windowTouchedTop && inTopExpandArea
      touchedBottom = windowTouchedBottom && inBottomExpandArea
    }

    const touchedEdge = touchedTop || touchedBottom

    // If not touched edge, shrink the glow
    if (!touchedEdge && this.inExpandStates()) {
      this.glowState = GLOW_STATES.SHOULD_SHRINK
    }

    // If touched edges, expand the glow
    if (
      touchedEdge &&
      this.inShrinkStates() &&
      this.glowState !== GLOW_STATES.SHRINKING &&
      this.allQuickToPaused()
    ) {
      if (touchedTop) this.glowState = GLOW_STATES.SHOULD_EXPAND_TOP
      else if (touchedBottom) this.glowState = GLOW_STATES.SHOULD_EXPAND_BOTTOM 
    }
  }

  shrink () {
    if (this.glowState === GLOW_STATES.SHRINKING) return

    if (this.expandTimeline) this.expandTimeline.pause()

    this.glowState = GLOW_STATES.SHRINKING
    this.shrinkTimeline = gsap.timeline({
      paused: true,
      default: DEFAULT_ANIMATION_VARS,
      onComplete: () => {
        this.glowState = GLOW_STATES.SHRINKED

        this.shrinkTimeline.invalidate()
        this.shrinkTimeline.kill()
        this.shrinkTimeline = null
      }}
    )
      .to(this.cursor, {
        borderRadius: '50%',
        duration: 0.15
      }, 0)
      .to(this.cursor, {
        width: GLOW_SIZE,
        height: GLOW_SIZE,
        backgroundImage: this.getGlowBackground(),
        filter: `blur(${BLUR_AMOUNTS.SHRINKED}px)`,
        opacity: 1,
      }, 0)
      .play()
  }

  expand (toTop = true) {
    if (
      this.glowState === GLOW_STATES.EXPANDING_TOP ||
      this.glowState === GLOW_STATES.EXPANDING_BOTTOM
    ) return

    const extraOffset = BLUR_AMOUNTS.EXPANDED + 50
    const expandHeight = this.touchedTop() ? this.expandHeight().top : this.expandHeight().bottom
    const remainHeight = window.innerHeight - expandHeight
    const edgeInset = {
      top: this.touchedTop() ? -extraOffset : remainHeight,
      right: -extraOffset,
      bottom: this.touchedTop() ? remainHeight : -extraOffset,
      left: -extraOffset
    }

    this.glowState = toTop ? GLOW_STATES.EXPANDING_TOP : GLOW_STATES.EXPANDING_BOTTOM
    this.expandTimeline = gsap.timeline({
      paused: true,
      default: DEFAULT_ANIMATION_VARS,
      onComplete: () => {
        this.glowState = toTop ? GLOW_STATES.EXPANDED_TOP : GLOW_STATES.EXPANDED_BOTTOM

        this.expandTimeline.invalidate()
        this.expandTimeline.kill()
        this.expandTimeline = null
      }}
    )
      .to(this.cursor, {
        width: `calc(100% + ${extraOffset * 2}px`,
        filter: `blur(${BLUR_AMOUNTS.EXPANDED}px)`,
        opacity: 1
      }, 0)
      .to(this.cursor, {
        backgroundImage: this.getGlowBackground(),
        duration: 0.6
      }, 0)
      .to(this.cursor, {
        borderRadius: 0,
        duration: 1
      }, 0)
      .to(this.cursor, {
        height: expandHeight + extraOffset,
        duration: 1
      }, '0.05')

    if (toTop) {
      this.expandTimeline
        .to(this.cursor, {
          top: edgeInset.top
        }, 0)
        .to(this.cursor, {
          right: edgeInset.right,
          bottom: edgeInset.bottom,
          left: edgeInset.left,
          duration: 0.8
        }, 0)
    } else {
      this.expandTimeline
        .to(this.cursor, {
          bottom: edgeInset.bottom
        }, 0)
        .to(this.cursor, {
          top: edgeInset.top,
          right: edgeInset.right,
          left: edgeInset.left,
          duration: 0.8
        }, 0)
    }

    this.expandTimeline.play()
  }

  offsetToExpand () {
    const expandHeight = this.expandHeight()
    const offsets = {
      top: Math.min(window.innerHeight * 0.56, OFFSETS_TO_EXPAND.TOP),
      bottom: Math.min(window.innerHeight * 0.23, OFFSETS_TO_EXPAND.BOTTOM),
    }

    return {
      top: this.inExpandStates() ? expandHeight.top : offsets.top,
      bottom: this.inExpandStates() ? expandHeight.bottom : offsets.bottom,
    }
  }

  expandHeight () {
    let top = Math.min(window.innerHeight * 0.8, EXPAND_HEIGHTS.TOP)
    let bottom = Math.min(window.innerHeight * 0.6, EXPAND_HEIGHTS.BOTTOM)

    if (this.topMakerEl) top = this.topMakerEl.offsetHeight
    if (this.bottomMakerEl) bottom = this.bottomMakerEl.offsetHeight

    return { top, bottom }
  }

  pauseAllQuickTo () {
    Object.values(this.quickTo).forEach((qt) => qt.tween.pause().invalidate())
  }

  resetAllQuickToDuration () {
    return !Object.values(this.quickTo).some((qt) => !qt.tween.duration(DEFAULT_ANIMATION_VARS.duration))
  }

  allQuickToPaused () {
    return !Object.values(this.quickTo).some((qt) => !qt.tween.paused)
  }

  inShrinkStates () {
    return this.glowState >= GLOW_STATES.SHOULD_SHRINK && this.glowState <= GLOW_STATES.SHRINKED
  }

  inExpandStates () {
    return this.glowState >= GLOW_STATES.SHOULD_EXPAND_TOP && this.glowState <= GLOW_STATES.EXPANDED_BOTTOM
  }

  touchedTop () {
    return this.glowState >= GLOW_STATES.SHOULD_EXPAND_TOP && this.glowState <= GLOW_STATES.EXPANDED_TOP
  }

  touchedBottom () {
    return this.glowState >= GLOW_STATES.SHOULD_EXPAND_BOTTOM && this.glowState <= GLOW_STATES.EXPANDED_BOTTOM
  }

  isOnMobile () {
    return window.innerWidth <= MOBILE_BREAKPOINT
  }

  getGlowBackground () {
    let bgName = ''
    bgName += this.inExpandStates() ? 'EXPAND' : 'SHRINK'
    bgName += window.isDarkMode ?'_DARK' :'_LIGHT'
    bgName += this.touchedTop() ?'_TOP' : this.touchedBottom() ? '_BOTTOM' : ''

    return GLOW_BACKGROUNDS[bgName]
  }
}

customElements.define('glow-cursor', GlowCursor)
